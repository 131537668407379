import React, { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { Footer } from "./components/Footer/Footer";
import { history } from "./utils/history";
import { useDispatch, useSelector } from "react-redux";
import {
  asyncLogout,
  clearUserState,
  selectUser,
} from "./features/auth/authSlice";
import useMediaQuery from "hooks/useMediaQuery";
// import { HomeBanner } from "components/HomeBanner/HomeBanner";
import Navbar from "components/Navbar/Navbar";
import { clearSummaryState } from "./features/summary/summarySlice";

export default function App() {
  history.navigate = useNavigate();
  history.location = useLocation();
  const location = history.location;
  const isLoggedIn = useSelector(selectUser);
  const isVerified = isLoggedIn && isLoggedIn.emailVerified;
  const isAdmin = isLoggedIn && isLoggedIn.isAdmin;
  const isNotOnVerifyEmailPage = history.location.pathname !== "/verify-email";
  const isHome = history.location.pathname === "/";
  const dispatch = useDispatch();
  const isNotMobile = useMediaQuery("(min-width: 600px)");
  const [showBanner, setShowBanner] = useState(true);

  useEffect(() => {
    if (isLoggedIn && !isVerified && isNotOnVerifyEmailPage) {
      history.navigate("/confirm-email");
    }
  }, [isLoggedIn, isVerified, isNotOnVerifyEmailPage, location.pathname]);

  const handleLogOut = () => dispatch(asyncLogout());

  if (
    localStorage.getItem("app_version") === null ||
    localStorage.getItem("app_version") !== process.env.REACT_APP_VERSION
  ) {
    dispatch(clearSummaryState());
    dispatch(clearUserState());

    localStorage.setItem("app_version", process.env.REACT_APP_VERSION);
  }

  return (
    <div id="app-container" className="h-full">
      <>
        <div className="container mx-auto px-4 sm:px-46 w-full content">
          <Navbar
            isLoggedIn={isLoggedIn}
            isAdmin={isAdmin}
            onLogout={handleLogOut}
          />
          {/*  {isNotMobile && !isLoggedIn && showBanner && isHome && (
            <HomeBanner onClose={() => setShowBanner(false)} />
          )} */}
          <Outlet context={isAdmin} />
        </div>
        <Footer className=" w-full" />
      </>
    </div>
  );
}
