import { createSlice, current } from "@reduxjs/toolkit";

const initialState = {
  summaries: [],
  queueIsFull: false,
  queueIsDone: false,
};

export const summarySlice = createSlice({
  name: "summary",
  initialState,
  reducers: {
    addSummaryToQueue: (state, action) => {
      const alreadyInQueue = (summary) =>
        summary.video_id === action.payload.video_id;
      if (!current(state.summaries).some(alreadyInQueue)) {
        state.summaries = [action.payload, ...state.summaries];
        state.queueIsFull = state.summaries.length > 2;
      }
    },
    clearSummaryState: (state) => {
      state.summaries = [];
      state.queueIsFull = false;
      state.queueIsDone = false;
    },
    removeSummaryFromQueue: (state, action) => {
      state.summaries = state.summaries.filter(
        (summary) => summary.id !== action.payload
      );
      state.queueIsFull = state.summaries.length > 2;
    },

    summaryCreationComplete: (state, action) => {
      state.summaries.find(
        (summary) => summary.id === action.payload
      ).isLoading = false;
    },
    summaryAddText: (state, action) => {
      state.summaries.find((summary) => summary.id === action.payload.id).text =
        action.payload.text;
    },
    summaryIncrementProgressBarValue: (state, action) => {
      state.summaries.find(
        (summary) => summary.id === action.payload.id
      ).progressBarValue = action.payload.progressBarValue;
    },
    checkSummariesStatus: (state) => {
      state.queueIsDone =
        state.summaries.every((summary) => !summary.isLoading) &&
        state.summaries.length === 3;
    },
    resetQueue: (state) => {
      state.summaries = [];
      state.queueIsFull = false;
      state.queueIsDone = false;
    },
    UnCompleteSummariesIds: (state) => {
      return state.summaries
        .filter((summary) => summary.isLoading)
        .map((summary) => summary.id);
    },
  },
});

export const selectSummaryQueue = (state) =>
  state.persistedSummaryReducer.summaries;
export const selectSummaryQueueStorageLimit = (state) =>
  state.persistedSummaryReducer.queueIsFull;
export const selectSummaryQueueStatus = (state) =>
  state.persistedSummaryReducer.queueIsDone;

export const useSelectionUnCompleteSummariesIds = (state) =>
  state.persistedSummaryReducer.summaries
    .filter((summary) => summary.isLoading)
    .map((summary) => summary.id);

export const {
  addSummaryToQueue,
  summaryCreationComplete,
  resetQueue,
  checkSummariesStatus,
  removeSummaryFromQueue,
  summaryAddText,
  UnCompleteSummariesIds,
  summaryIncrementProgressBarValue,
  clearSummaryState,
} = summarySlice.actions;

export default summarySlice.reducer;
