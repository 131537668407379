import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { RouterProvider } from "react-router-dom";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { ChakraProvider } from "@chakra-ui/react";
import { theme } from "CustomChakraTheme";
import { router } from "./router";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { PersistGate } from "redux-persist/integration/react";
import { appStore, persistor } from "./app/store";
import Echo from "laravel-echo";
import { authClient } from "features/auth/authSlice";

window.Pusher = require("pusher-js");

window.Echo = new Echo({
  broadcaster: "pusher",
  cluster: "mt1",
  key: process.env.REACT_APP_WEBSOCKETS_KEY,
  wsHost: process.env.REACT_APP_WEBSOCKETS_SERVER,
  encrypted: true,
  wsPort: 443,
  wssPort: 443,
  forceTLS: false,
  disableStats: true,
  enabledTransports: ["ws", "wss"],
  authorizer: (channel, options) => {
    return {
      authorize: (socketId, callback) => {
        authClient
          .post(process.env.REACT_APP_API_URL + "/broadcasting/auth", {
            socket_id: socketId,
            channel_name: channel.name,
          })
          .then((response) => {
            callback(null, response.data);
          })
          .catch((error) => {
            callback(error);
          });
      },
    };
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <Provider store={appStore}>
      <PersistGate loading={null} persistor={persistor}>
        <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
          <ChakraProvider theme={theme}>
            <div className="container mx-auto w-full h-full">
              <RouterProvider router={router} />
            </div>
          </ChakraProvider>
        </GoogleOAuthProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
