import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

function AdminProtected({ children }) {
  const isSignedIn = useSelector((state) => state.persistedAuthReducer.user);
  const isAdmin = isSignedIn && isSignedIn.isAdmin;

  if (!isSignedIn) {
    return <Navigate to="/login" replace />;
  }
  if (isSignedIn && !isAdmin) {
    return <Navigate to="/access-denied" replace />;
  }
  return children;
}
export default AdminProtected;
