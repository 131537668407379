"use client";
import { Link as ChakraLink, useMediaQuery } from "@chakra-ui/react";
import {
  NavLink,
  Link as ReactRouterLink,
  useLocation,
} from "react-router-dom";

import {
  ChevronDownIcon,
  ChevronRightIcon,
  CloseIcon,
  HamburgerIcon,
  SettingsIcon,
  TimeIcon,
} from "@chakra-ui/icons";
import {
  Box,
  Button,
  Collapse,
  Flex,
  Icon,
  IconButton,
  Image,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Stack,
  Text,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import {
  authClient,
  selectUser,
  setSubscription,
} from "features/auth/authSlice";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLockBodyScroll } from "@uidotdev/usehooks";

export default function Navbar({ isLoggedIn, isAdmin, onLogout }) {
  // const { isOpen, onOpen, onClose } = useDisclosure();
  const [showMobileNav, setShowMobileNav] = useState(false);
  const btnRef = useRef();
  const location = useLocation();
  const user = useSelector(selectUser);
  const dispatch = useDispatch();
  const [isLargerThan360px] = useMediaQuery("(min-width: 360px)");

  const handleBurgerClick = () => {
    setShowMobileNav(!showMobileNav);
  };

  const getSubscription = async () => {
    try {
      const res = await authClient.get("api/subscription");
      dispatch(setSubscription(res.data.data));
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    if (showMobileNav) {
      setShowMobileNav(false);
    }
  }, [location]);

  // useEffect(() => {
  //   if (user?.emailVerified) {
  //     //getSubscription();
  //   }
  // }, []);

  return (
    <Box>
      <Flex
        bg={useColorModeValue("white", "gray.800")}
        color={useColorModeValue("gray.600", "white")}
        minH={"60px"}
        py={{ base: 2 }}
        px={{ base: 4 }}
        alignItems={"center"}
        justify={"center"}
      >
        <Flex
          flex={{ base: 1, lg: "auto" }}
          ml={{ base: -2 }}
          display={{ base: "flex", lg: "none" }}
        >
          <IconButton
            ref={btnRef}
            onClick={handleBurgerClick}
            icon={
              showMobileNav ? (
                <CloseIcon w={3} h={3} />
              ) : (
                <HamburgerIcon w={6} h={6} />
              )
            }
            variant={"ghost"}
            aria-label={"Toggle Navigation"}
          />
        </Flex>
        <Flex
          flex={{ base: 1 }}
          align={"center"}
          justify={{ base: "center", md: "start" }}
        >
          <ChakraLink as={ReactRouterLink} to={"/"}>
            <Image
              mr={
                user
                  ? { base: 20, md: 0 }
                  : { base: isLargerThan360px ? 36 : 32, sm: 60, md: 0 }
              }
              boxSize="100px"
              objectFit="cover"
              src="/logo.png"
              alt="Logo quickrecap.ai"
            />
          </ChakraLink>

          <Flex display={{ base: "none", lg: "flex" }} ml={10}>
            <DesktopNav isAdmin={isAdmin} isLoggedIn={isLoggedIn} />
          </Flex>
        </Flex>

        <Stack
          flex={{ base: "none" }}
          justify={"flex-end"}
          direction={"row"}
          align={"center"}
        >
          {!isLoggedIn && (
            <ChakraLink
              as={ReactRouterLink}
              w={12}
              fontSize={"sm"}
              variant={"link"}
              fontWeight={400}
              to="/login"
            >
              Log In
            </ChakraLink>
          )}
          {!isLoggedIn && (
            <ChakraLink as={ReactRouterLink} to="sign-up">
              <Button
                _hover={{ boxShadow: "md" }}
                backgroundColor={"#2a2a2a"}
                display={{ base: "none", md: "inline-flex" }}
                fontSize={"sm"}
                fontWeight={600}
                color={"white"}
              >
                Sign Up
              </Button>
            </ChakraLink>
          )}
          {/*{isLoggedIn && (*/}
          {/*  <Flex*/}
          {/*    display={{ base: "none", lg: "flex" }}*/}
          {/*    alignItems={"center"}*/}
          {/*    justify={"center"}*/}
          {/*    border={"1px"}*/}
          {/*    borderColor={"gray.300"}*/}
          {/*    borderRadius={"full"}*/}
          {/*    p={2}*/}
          {/*  >*/}
          {/*    <Box>*/}
          {/*      <TimeIcon boxSize={"4"} mx={1} />*/}
          {/*    </Box>*/}
          {/*    <Text mr={"1"} mt={1}>*/}
          {/*      {user.subscription.minutes} minutes left*/}
          {/*    </Text>*/}
          {/*  </Flex>*/}
          {/*)}*/}
          {isLoggedIn && <PopoverMenu user={user} onLogout={onLogout} />}
        </Stack>
      </Flex>

      {showMobileNav && (
        <>
          <Box
            position={"absolute"}
            top={0}
            left={0}
            h={"100vh"}
            w={"60vw"}
            maxW={"60vw"}
            backgroundColor={"white"}
            zIndex={10000}
            shadow={"2xl"}
          >
            <Flex
              pb={4}
              pt={3}
              pl={7}
              pr={5}
              w={"full"}
              backgroundColor={"white"}
              borderBottom={"1px solid"}
              borderColor={"gray.200"}
              justify={"space-between"}
              align={"center"}
            >
              <Text fontWeight={"bold"}>Menu</Text>
              <CloseIcon
                boxSize={2.5}
                mb={1}
                onClick={() => setShowMobileNav(false)}
              />
            </Flex>
            <Box p={0} m={0} backgroundColor={"white"}>
              <MobileNav
                user={user}
                isAdmin={isAdmin}
                isLoggedIn={isLoggedIn}
                onLogout={onLogout}
              />
            </Box>
            {/*{isLoggedIn && (*/}
            {/*  <Box*/}
            {/*    position={"absolute"}*/}
            {/*    bottom={0}*/}
            {/*    borderTop={"1px solid"}*/}
            {/*    py={5}*/}
            {/*    borderColor={"gray.200"}*/}
            {/*    w={"full"}*/}
            {/*  >*/}
            {/*    <Flex w={"full"} alignItems={"center"} justify={"center"}>*/}
            {/*      <TimeIcon mr={2} mb={1} />*/}
            {/*      <Text>{user.subscription.minutes} minutes left</Text>*/}
            {/*    </Flex>*/}
            {/*  </Box>*/}
            {/*)}*/}
          </Box>
          <Box
            onClick={() => setShowMobileNav(false)}
            position={"absolute"}
            h={"100vh"}
            w={"100vw"}
            top={0}
            backgroundColor={"rgba(0,0,0,0.5)"}
            zIndex={999}
          ></Box>
        </>
      )}
    </Box>
  );
}

const DesktopNav = ({ isLoggedIn, isAdmin }) => {
  const linkColor = useColorModeValue("gray.600", "gray.200");
  const linkHoverColor = useColorModeValue("gray.800", "white");

  return (
    <Stack direction={"row"} spacing={4}>
      {NAV_ITEMS.map((navItem) => (
        <Box
          display="flex"
          alignItems={"center"}
          justifyContent={"center"}
          key={navItem.label}
        >
          {navItem.role === "user" && (isLoggedIn || isAdmin) && (
            <Box
              p={2}
              href={navItem.href ?? "#"}
              fontSize={"sm"}
              fontWeight={500}
              color={linkColor}
              _hover={{
                borderBottom: "solid",
                borderBottomColor: "gray.200",
                textDecoration: "none",
                color: linkHoverColor,
              }}
            >
              <ReactRouterLink
                to={navItem.href}
                _hover={{ textDecoration: "none" }}
              >
                {navItem.label}
              </ReactRouterLink>
            </Box>
          )}
          {navItem.role === "admin" && isAdmin && (
            <Box
              p={2}
              href={navItem.href ?? "#"}
              fontSize={"sm"}
              fontWeight={500}
              color={linkColor}
              _hover={{
                borderBottom: "solid",
                borderBottomColor: "gray.200",
                textDecoration: "none",
                color: linkHoverColor,
              }}
            >
              <ReactRouterLink
                to={navItem.href}
                _hover={{ textDecoration: "none" }}
              >
                {navItem.label}
              </ReactRouterLink>
            </Box>
          )}
          {navItem.role === "guest" &&
            (!isLoggedIn || navItem.onlyGuest === false) && (
              <Box
                p={2}
                href={navItem.href ?? "#"}
                fontSize={"sm"}
                fontWeight={500}
                color={linkColor}
                _hover={{
                  borderBottom: "solid",
                  borderBottomColor: "gray.200",
                  textDecoration: "none",
                  color: linkHoverColor,
                }}
              >
                <ReactRouterLink
                  to={navItem.href}
                  _hover={{ textDecoration: "none" }}
                >
                  {navItem.label}
                </ReactRouterLink>
              </Box>
            )}
        </Box>
      ))}
    </Stack>
  );
};

const DesktopSubNav = ({ label, href, subLabel }) => {
  return (
    <ReactRouterLink to={href}>
      <Box
        p={2}
        rounded={"md"}
        _hover={{ bg: useColorModeValue("red.50", "red.900") }}
      >
        <Stack direction={"row"} align={"center"}>
          <Box>
            <Text
              transition={"all .3s ease"}
              _groupHover={{ color: "red.400" }}
              fontWeight={500}
            >
              {label}
            </Text>
            <Text fontSize={"sm"}>{subLabel}</Text>
          </Box>
          <Flex
            transition={"all .3s ease"}
            transform={"translateX(-10px)"}
            opacity={0}
            _groupHover={{ opacity: "100%", transform: "translateX(0)" }}
            justify={"flex-end"}
            align={"center"}
            flex={1}
          >
            <Icon color={"pink.400"} w={5} h={5} as={ChevronRightIcon} />
          </Flex>
        </Stack>
      </Box>
    </ReactRouterLink>
  );
};

const MobileNav = ({ isLoggedIn, isAdmin }) => {
  useLockBodyScroll();
  return (
    <Stack
      className={"navbar"}
      margin={0}
      w={"full"}
      bg={useColorModeValue("white", "gray.800")}
      py={4}
      display={{ lg: "none" }}
    >
      {NAV_ITEMS.map((navItem) => (
        <>
          {navItem.role === "user" && (isLoggedIn || isAdmin) && (
            <MobileNavItem key={navItem.label} {...navItem} />
          )}
          {navItem.role === "admin" && isAdmin && (
            <MobileNavItem key={navItem.label} {...navItem} />
          )}
          {navItem.role === "guest" &&
            (!isLoggedIn || navItem.onlyGuest === false) && (
              <MobileNavItem key={navItem.label} {...navItem} />
            )}
        </>
      ))}
      {!isLoggedIn && (
        <MobileNavItem
          key={"signup"}
          icon="➡"
          label={"Sign Up"}
          href={"/sign-up"}
        />
      )}
    </Stack>
  );
};

const MobileNavItem = ({
  icon = "",
  label,
  children,
  href,
  onLogout = null,
}) => {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <Stack spacing={4} onClick={children && onToggle}>
      <Box
        fontSize={"1.2rem"}
        py={2}
        as="a"
        href={href ?? "#"}
        justifyContent="space-between"
        alignItems="center"
        _hover={{
          textDecoration: "none",
        }}
      >
        <NavLink to={href} onClick={onLogout}>
          <Text
            pl={4}
            fontWeight={600}
            color={useColorModeValue("gray.600", "gray.200")}
          >
            {`${icon} ${label} `}
          </Text>
        </NavLink>
        {children && (
          <Icon
            as={ChevronDownIcon}
            transition={"all .25s ease-in-out"}
            transform={isOpen ? "rotate(180deg)" : ""}
            w={6}
            h={6}
          />
        )}
      </Box>

      <Collapse in={isOpen} animateOpacity style={{ marginTop: "0!important" }}>
        <Stack
          mt={2}
          pl={4}
          borderLeft={1}
          borderStyle={"solid"}
          borderColor={useColorModeValue("gray.200", "gray.700")}
          align={"start"}
        >
          {children &&
            children.map((child) => (
              <Box as="a" key={child.label} py={2} href={child.href}>
                {child.label}
              </Box>
            ))}
        </Stack>
      </Collapse>
    </Stack>
  );
};

const PopoverMenu = ({ user, onLogout }) => {
  const { isOpen, onToggle, onClose } = useDisclosure();
  const location = useLocation();

  useEffect(() => {
    if (isOpen) {
      onClose();
    }
  }, [location]);
  return (
    <Popover isOpen={isOpen} onClose={onClose} returnFocusOnClose={false}>
      <PopoverTrigger>
        <Button
          pt={1}
          paddingRight={{ base: 0, md: 3 }}
          backgroundColor={"#fff"}
          rightIcon={<SettingsIcon mb={1} />}
          onClick={onToggle}
        >
          Profile
        </Button>
      </PopoverTrigger>
      <PopoverContent>
        <Stack>
          {/*<DesktopSubNav label={user.email} href={"/manage-plans"} />*/}
          {/*<DesktopSubNav label={"Manage Plans"} href={"/manage-plans"} />*/}
          {/*<DesktopSubNav label={"Refer a Friend"} href={"refer-a-friend"} />*/}
          <DesktopSubNav
            label={"Report a problem"}
            href={"/support/report-bug"}
          />
          <DesktopSubNav label={"Contact us"} href={"/support/contact-us"} />
          <Box onClick={() => onLogout()} _hover={{ cursor: "pointer" }}>
            <DesktopSubNav label={"Log out"} />
          </Box>
        </Stack>
      </PopoverContent>
    </Popover>
  );
};

const NAV_ITEMS = [
  {
    label: "Home",
    icon: "🏠",
    href: "/",
    onlyGuest: false,
    role: "guest",
  },
  {
    label: "History",
    icon: "📜",
    role: "user",
    onlyGuest: false,
    href: "/summary-history",
  },
  // {
  //   label: "Pricing",
  //   icon: "🏷️",
  //   role: "guest",
  //   onlyGuest: true,
  //   href: "/pricing",
  // },
  {
    label: "Users List",
    icon: "📈",
    role: "admin",
    onlyGuest: false,
    href: "/users-list",
  },
  {
    label: "Feedbacks",
    icon: "⭐",
    role: "admin",
    onlyGuest: false,
    href: "/feedbacks",
  },
  {
    label: "Prompt",
    icon: "⌨️",
    role: "admin",
    onlyGuest: false,
    href: "/admin-prompt",
  },
  {
    label: "Profile",
    children: [
      {
        label: "Job Board",
        subLabel: "Find your dream design job",
        href: "#",
      },
      {
        label: "Freelance Projects",
        subLabel: "An exclusive list for contract work",
        href: "#",
      },
    ],
  },
];
